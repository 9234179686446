.sjs-client-app {
  color: #404040;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.form-creator-logo {
  vertical-align: middle;
  height: 75px;
  padding: 25px;
}

.sjs-client-app__header {
  background-color: var(--primary, #f76c1f);
  padding: 10px;
  padding-bottom: 0;
}

.sjs-nav-button {
  display: inline-block;
  margin-left: 24px;
  font-weight: bold;
  font-size: 22px;
  text-decoration: none;
  line-height: 40px;
  color: #f76c1f;
}

.sjs-nav-button:hover,
.sjs-nav-button.active {
  text-decoration: underline;
}

.sjs-client-app__content {
  position: fixed;
  top: 64px;
  width: 100%;
  left: 0;
  bottom: 0;
  overflow: auto;
  user-select: none;
}

h1 {
  padding-left: 24px;
}

.sjs-client-app__content--about h1,
.sjs-client-app__content--forms-list h1 {
  padding: 0;
}

.sjs-client-app__content--forms-list,
.sjs-client-app__content--about {
  max-width: 800px;
  margin: 0 auto;
}

.sjs-client-app__footer {
}

.sjs-editor-container {
  height: calc(100% - 2px);
}

.sjs-results-container {
  height: calc(100% - 88px);
}

.sjs-results-content {
  height: 100%;
}

.sjs-results-placeholder {
  line-height: 200px;
  text-align: center;
  border: 1px dotted lightgray;
}
